<template>
  <div>
    <help/>
  </div>
</template>

<script>
import Help from "../components/Help/index";
export default {
  components: { Help },
};
</script>

<style></style>
