<template>
  <div>
    <video-pop-up />
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />
    <!--====== HEADER PART START ======-->
    <header-one image="logo.png" @toggleSidebar="toggleSidebar" />
    <slider />
    <!--====== HEADER PART ENDS ======-->

    <!--====== POST PART START ======-->

    <!-- <post-part-home-one /> -->

    <!--====== POST PART ENDS ======-->

    <!--====== POST GALLERY PART START ======-->
    <post-gallery />

    <!--====== POST GALLERY PART ENDS ======-->
    <!-- <fun-fact-area /> -->
    <!--====== FEATURE PART START ======-->
    <features />

    <!--====== FEATURE PART ENDS ======-->

    <!--====== TRENDING NEWS PART START ======-->
    <trending-news />
    <!-- <future-area /> -->

    <!--====== TRENDING NEWS PART ENDS ======-->

    <!--====== SINGLE PLAY POST PART START ======-->
    <!-- <single-play-post /> -->

    <!--====== VIDEO NEWS PART START ======-->

    <!-- <video-news /> -->

    <!--====== VIDEO NEWS PART ENDS ======-->

    <!--====== ALL POST PART START ======-->


    <!--====== ALL POST PART ENDS ======-->

    <!--====== FOOTER PART START ======-->
    <footer-one />

    <!--====== FOOTER PART ENDS ======-->

    <!--====== GO TO TOP PART START ======-->
    <div class="go-top-area">
      <div class="go-top-wrap">
        <div class="go-top-btn-wrap">
          <a href="#" class="go-top go-top-btn d-block">
            <i class="fa fa-angle-double-up"></i>
            <i class="fa fa-angle-double-up"></i>
          </a>
        </div>
      </div>
    </div>
    <!--====== GO TO TOP PART ENDS ======-->
  </div>
</template>
<script>
import HeaderOne from "../Utility/Header/index";
import Slider from "./Slider.vue";
// import PostPartHomeOne from "./PostPartHomeOne.vue";
import PostGallery from "./PostGallery.vue";
// import FunFactArea from "./FunFactArea.vue";
import Features from "./Features.vue";
import TrendingNews from "./TrendingNews.vue";
// import FutureArea from "./FutureArea.vue";
// import SinglePlayPost from "./SinglePlayPost.vue";
// import VideoNews from "./VideoNews.vue";
// import EntertainmentNews from "./EntertainmentNews.vue";
// import SportsNews from "./SportsNews.vue";
// import BuisnessNews from "./BuisnessNews.vue";
// import SharePost from "./SharePost.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
import Drawer from "../Mobile/Drawer.vue";
import VideoPopUp from "../Utility/VideoPopUp.vue";
export default {
  components: {
    HeaderOne,
    Slider,
    // PostPartHomeOne,
    PostGallery,
    // FunFactArea,
    Features,
    TrendingNews,
    // SinglePlayPost,
    // VideoNews,
    // EntertainmentNews,
    // SportsNews,
    // BuisnessNews,
    // SharePost,
    // FutureArea,
    FooterOne,
    Drawer,
    VideoPopUp,
  },
  data: () => ({
    sidebar: false,
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style scoped>
/* play post */
.single-play-post-area .single-play-box {
  padding: 23px 12px !important;
}
</style>
