<template>
  <div>
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />
    <Header @toggleSidebar="toggleSidebar" />
    <PageHeader title="User Subscription" />
    <div class="ec-footer text-center">
        <v-tabs fixed-tabs background-color="white" dark color="primary">
          <v-tab>
            <v-icon>
              mdi-book-check
            </v-icon>
            Subscription
          </v-tab>
          <v-tab>
            <v-icon>
              mdi-book
            </v-icon>
            Subscription Plan
          </v-tab>
        
        
        <v-tab-item>
            <v-card flat>
              <v-card-title class="text-h5">
                An awesome title
              </v-card-title>
              <v-card-text>
                <p>
                  Duis lobortis massa imperdiet quam. Donec vitae orci sed dolor rutrum auctor. Vestibulum facilisis, purus nec pulvinar iaculis, ligula mi congue nunc, vitae euismod ligula urna in dolor. Praesent congue erat at massa.
                </p>

                <p>
                  Aenean posuere, tortor sed cursus feugiat, nunc augue blandit nunc, eu sollicitudin urna dolor sagittis lacus. Pellentesque egestas, neque sit amet convallis pulvinar, justo nulla eleifend augue, ac auctor orci leo non est. Etiam sit amet orci eget eros faucibus tincidunt. Donec sodales sagittis magna.
                </p>

                <p class="mb-0">
                  Ut leo. Suspendisse potenti. Duis vel nibh at velit scelerisque suscipit. Fusce pharetra convallis urna.
                </p>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-title class="text-h5">
                An even better title
              </v-card-title>
              <v-card-text>
                <p>
                  Maecenas ullamcorper, dui et placerat feugiat, eros pede varius nisi, condimentum viverra felis nunc et lorem. Sed hendrerit. Maecenas malesuada. Vestibulum ullamcorper mauris at ligula. Proin faucibus arcu quis ante.
                </p>

                <p class="mb-0">
                  Etiam vitae tortor. Curabitur ullamcorper ultricies nisi. Sed magna purus, fermentum eu, tincidunt eu, varius ut, felis. Aliquam lobortis. Suspendisse potenti.
                </p>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      <!-- <ul class="ec-footer__row" data-items="4">
        <li class="ec-footer__cell"><a class="ec-footer__link ec-link ec-link--muted link--icon-top footer__link--favorites footer__link--empty" href="/cart"><span class="svg-icon"><svg width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg"><path d="M9 16c-.16 0-.32-.063-.442-.188L2.29 9.543C2.21 9.47 0 7.375 0 4.875 0 1.823 1.798 0 4.8 0 6.56 0 8.207 1.438 9 2.25 9.794 1.437 11.44 0 13.2 0 16.2 0 18 1.823 18 4.875c0 2.5-2.21 4.594-2.3 4.688l-6.258 6.25c-.12.124-.28.187-.442.187zm5.98-7.132c.105-.103.215-.216.356-.375.235-.264.467-.56.684-.88.618-.914.98-1.85.98-2.738C17 2.373 15.647 1 13.2 1c-1.098 0-2.358.795-3.485 1.95L9 3.68l-.715-.73C7.158 1.794 5.898 1 4.8 1 2.354 1 1 2.373 1 4.875 1 6.4 2.043 7.967 2.997 8.835L9 14.84l5.98-5.972z" fill="currentColor" fill-rule="nonzero"></path></svg></span> Subscription</a></li>
        <li class="ec-footer__cell"><a class="ec-footer__link ec-link ec-link--muted link--icon-top footer__link--shopping-cart footer__link--empty" href="/cart"><span class="svg-icon"><svg width="16" height="20" viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M1 5v13.006c0 .548.446.994.994.994h12.012c.548 0 .994-.446.994-.994V5H1zm15-1v14.006c0 1.1-.895 1.994-1.994 1.994H1.994C.894 20 0 19.105 0 18.006V4h16z" fill="currentColor" fill-rule="nonzero"></path><path d="M5 7s-.005.5-.504.5C3.996 7.5 4 7 4 7V4c0-2.21 1.79-4 4-4s4 1.79 4 4v3s-.002.5-.5.5S11 7 11 7V4c0-1.657-1.343-3-3-3S5 2.343 5 4v3z" fill="currentColor"></path></g></svg></span> Subscription Plan</a></li>
      </ul>
      <div class="ec-cart ec-cart--empty"><div class="ec-cart__message">You have no Subscription</div>
      </div>-->
    </div>
    <footer-one />
      <div class="go-top-area">
        <div class="go-top-wrap">
          <div class="go-top-btn-wrap">
            <a href="#" class="go-top go-top-btn d-block">
              <i class="fa fa-angle-double-up"></i>
              <i class="fa fa-angle-double-up"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import PageHeader from "../Utility/Header/PageHeader.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
import Header from "../Utility/Header/index";
export default {
  components: { Drawer, PageHeader, FooterOne, Header },
  data () {
      return {
        sidebar: false,
        tabs: null,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      }
    },
 
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style>
.v-tabs--vertical > .v-tabs-bar .v-tab {
    height: 61px;
}
  .ec-footer{
    padding-bottom:20px;
    padding-top:40px;
  }
  .ec-footer li{
    list-style: none;;
  }
  ul.ec-footer__row{
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
    padding-bottom:10px;
    border-bottom: 1px dashed gray;
  }
  .ec-footer__cell
  {
    width: 150px;
  }
  .ec-link--muted{
    color: #757575;
    transition: color .12s ease-out 0s;
  }
  .ec-cart__message{
    padding-top:40px;
    padding-bottom:40px;
    font-weight:700;
  }
</style>
