<template>
  <div>
    <wallet />
  </div>
</template>

<script>
import Wallet from "../components/Wallet/index";
export default {
  components: { Wallet },
};
</script>

<style></style>
