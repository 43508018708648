<template>
  <div class="contact-info-area pb-90">
    <div class="container">
      <!-- <div class="row justify-content-center">
        <div class="col-lg-3 col-md-6">
          <div class="contact-info-item mt-30">
            <h3 class="title">BLIS Sales</h3>
            <span><i class="fas fa-envelope"></i> EMAIL:</span>
            <ul>
              <li>blissales@bernama.com</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="contact-info-item mt-30">
            <h3 class="title">BLIS Administrator</h3>
            <span><i class="fas fa-envelope"></i> EMAIL:</span>
            <ul>
              <li>blisadmin@bernama.com</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="contact-info-item mt-30">
            <h3 class="title">BERNAMA</h3>
            <span><i class="fas fa-map-marker-alt"></i> LOCATION:</span>
            <ul>
              <li>Malaysian National News Agency</li>
              <li>Wisma BERNAMA,</li>
              <li>No 28, Jalan 1/65A,</li>
              <li>Off Jalan Tun Razak,50400 Kuala Lumpur, Malaysia</li>
            </ul>
          </div>
        </div>
        
      </div> -->
      <div class="row">
        <div class="col-lg-12">
          <div class="map-area">
            <iframe
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Wisma%20Bernama+(BERNAMA)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              width="600"
              height="450"
              style="border: 0"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
