<template>
  <div>
    <profile />
  </div>
</template>

<script>
import Profile from "../components/Profile/index";
export default {
  components: { Profile },
};
</script>

<style></style>
