<template>
<v-card>
    <v-tabs vertical class="ml-5">
      <v-tab class="service-sidebar mt-5">
        <v-icon left>
          mdi-book-open
        </v-icon>
        Bernama News
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-book-open
        </v-icon>
        Archival News
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-book-open
        </v-icon>
        Mandarin News
      </v-tab>
      <div class="service-sidebar-help mb-5">
        <h4 class="title">Need Help?</h4>
        <p>Call us and we will connect you with a <br>team member who can help.</p>
        <span><i class="fa fa-phone"></i> 603-2693 9933</span>
      </div>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <div class="single-project">
            <div class="single-project-content">
              <h3 class="title">Bernama News</h3>
              <p>Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the ndustry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries. Lorem Ipsum is simply dummy text of the new design printng and type setting Ipsum Take a look at our round up of the best shows coming soon to your telly box has been the is industrys. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has industr standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of.</p>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="single-project-thumb mt-30">
                  <img src="@/assets/images/single-project-thumb-2.jpg" alt="">
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="single-project-thumb mt-30">
                  <img src="@/assets/images/single-project-thumb-3.jpg" alt="">
                </div>
              </div>
            </div>
            <div class="single-project-text">
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores impedit odio molestiae quam accusantium facilis, ut aspernatur sequi ex amet dolore odit excepturi, quo aliquid sint non explicabo deleniti? Ratione.</p>
              <p class="text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Optio magnam explicabo commodi pariatur modi magni excepturi, velit nulla eligendi expedita soluta corrupti sunt similique ea culpa officiis minima enim quo. Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa quisquam maiores non recusandae modi amet possimus, iure dolor doloremque exercitationem eveniet, praesentium quod ipsum. Repellendus beatae quidem accusantium eaque distinctio?</p>
            </div>
          </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <div class="single-project">
            <div class="single-project-content">
              <h3 class="title">Archival News</h3>
              <p>
              Morbi nec metus. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis tellus, vitae iaculis lacus elit id tortor. Sed mollis, eros et ultrices tempus, mauris ipsum aliquam libero, non adipiscing dolor urna a orci. Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet, leo. Nunc sed turpis.
          </p>
            </div>
            

            <p>
              Suspendisse feugiat. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis tellus, vitae iaculis lacus elit id tortor. Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In hac habitasse platea dictumst. Fusce ac felis sit amet ligula pharetra condimentum.
            </p>

            <p>
              Sed consequat, leo eget bibendum sodales, augue velit cursus nunc, quis gravida magna mi a libero. Nam commodo suscipit quam. In consectetuer turpis ut velit. Sed cursus turpis vitae tortor. Aliquam eu nunc.
            </p>

            <p>
              Etiam ut purus mattis mauris sodales aliquam. Ut varius tincidunt libero. Aenean viverra rhoncus pede. Duis leo. Fusce fermentum odio nec arcu.
            </p>

            <p class="mb-0">
              Donec venenatis vulputate lorem. Aenean viverra rhoncus pede. In dui magna, posuere eget, vestibulum et, tempor auctor, justo. Fusce commodo aliquam arcu. Suspendisse enim turpis, dictum sed, iaculis a, condimentum nec, nisi.
            </p>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <div class="single-project">
            <div class="single-project-content">
              <h3 class="title">Mandarin News</h3>
              <p>
              Fusce a quam. Phasellus nec sem in justo pellentesque facilisis. Nam eget dui. Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In dui magna, posuere eget, vestibulum et, tempor auctor, justo.
            </p>
            </div>
            

            <p class="mb-0">
              Cras sagittis. Phasellus nec sem in justo pellentesque facilisis. Proin sapien ipsum, porta a, auctor quis, euismod ut, mi. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nam at tortor in tellus interdum sagittis.
            </p>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item class="mt-10">
        <v-card flat>
          <v-card-text>
            <p class="mb-0">
              BLIS MANUAL
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
  <!-- <section class="single-service-area">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-3 col-sm-3 order-lg-1">
          <div class="service-sidebar">
            <div class="service-sidebar-list">
              <ul>
                <li><a href="#">Bernama News</a></li>
                <li><a href="#">Archival News</a></li>
                <li><a href="#">Mandarin News</a></li>
                <li><a href="#">Foreign News</a></li>
                <li><a href="#">Info Providers</a></li>
                <li><a href="#">Sectoral Reports</a></li>
              </ul>
            </div>
            <div class="service-sidebar-help">
              <h4 class="title">Need Help?</h4>
              <p>Call us and we will connect you with a team member who can help.</p>
              <span><i class="fa fa-phone"></i> 603-2693 9933</span>
            </div>
          </div>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 order-lg-2">
          <div class="single-project mt-30">
            <div class="single-project-content">
              <h3 class="title">Bernama News</h3>
              <p>Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the ndustry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries. Lorem Ipsum is simply dummy text of the new design printng and type setting Ipsum Take a look at our round up of the best shows coming soon to your telly box has been the is industrys. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has industr standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of.</p>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="single-project-thumb mt-30">
                  <img src="@/assets/images/single-project-thumb-2.jpg" alt="">
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="single-project-thumb mt-30">
                  <img src="@/assets/images/single-project-thumb-3.jpg" alt="">
                </div>
              </div>
            </div>
            <div class="single-project-text">
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores impedit odio molestiae quam accusantium facilis, ut aspernatur sequi ex amet dolore odit excepturi, quo aliquid sint non explicabo deleniti? Ratione.</p>
              <p class="text">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Optio magnam explicabo commodi pariatur modi magni excepturi, velit nulla eligendi expedita soluta corrupti sunt similique ea culpa officiis minima enim quo. Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa quisquam maiores non recusandae modi amet possimus, iure dolor doloremque exercitationem eveniet, praesentium quod ipsum. Repellendus beatae quidem accusantium eaque distinctio?</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
</template>

<script>
    export default {
        name: "Aboutpage"
    }
</script>

<style scoped>
p{
  color: #7f8692;
    line-height: 34px;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: .6px;
    padding-bottom: 25px;
}
</style>
