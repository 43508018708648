<template>
  <Module />
</template>

<script>
import Module from "../components/Module/index";
export default {
  components: { Module },
};
</script>

<style></style>
