<template>
  <div class="page-title-area bg_cover pt-20 pb-20">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="page-title-item text-center">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item"><router-link to="/">Modules</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">{{ title }}</li>
              </ol>
            </nav>
            &nbsp;
            <nav aria-label="breadcrumb test">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/">Advanced Search&nbsp;<i class="fa fa-search icon"></i></router-link></li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="page-shadow">
      <img src="@/assets/images/page-shadow.png" alt="">
    </div> -->
  </div>
</template>

<script>
  export default {
    name: "ModuleHeader",
    props: {
      title: {
        type: String
      }
    }

  }
</script>

<style scoped>
i.fa.fa-search.icon {
    margin-top: 3px;
}
</style>
