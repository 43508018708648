<template>
  <List />
</template>

<script>
import List from "../components/List/index";
export default {
  components: { List },
};
</script>

<style></style>
