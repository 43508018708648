<template>
<div class="search-section">
  <div class="search-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex justify-content-between">
            <div style="padding-top:8px;">
              <router-link to="/">
                  <img src="@/assets/images/adobe.png" alt="logo" style="margin-bottom:7px;" class="d-none d-lg-block"/>
                  <img src="@/assets/images/empty.png" alt="logo" class="hidden-lg-up"/>
                </router-link>
            </div>
            <div>
              <div class="footer-search-up d-block d-lg-flex animated wow fadeInUp" data-wow-duration="1500ms">
                <div class="search-up-form">
                  <v-form v-on:submit="sub" action="/globalsearch">
                    <div class="input-box">
                      <input type="text" name="search" placeholder="Global Search">
                      <button><i class="fa fa-search"></i></button>
                    </div>
                  </v-form>
                </div>
                <div class="shape">
                  <img src="@/assets/images/shape/shape-5.png" alt="shape" width="70%">
                </div>
              </div>
            </div>
            <div>
               <theme-button class="themebtn d-none d-lg-block" style="margin-top: 10px;" />
              <img src="@/assets/images/empty.png" alt="logo" class="hidden-lg-up"/>
            </div>
          </div>
          
          
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  import ThemeButton from "@/components/ThemeButton.vue";
  export default {
  components: { ThemeButton },
  name: "GlobalSearch"
  }
</script>
<style scoped>

.themebtn {
    display: inline-block;
}


</style>
