<template>
  <div class="post__gallery__area modules-area">
    <div class="container">
      <div class="row" style="border-top: 10px solid #009ed8;">
        <div class="col-lg-8">
          <div class="modules-bg">
            <div class="row">
              <!-- <div class="col-lg-2 col-md-6 col-sm-6"> -->
              <div class="rowb">
                <div class="col-lg-2 col-md-3 col-sm-3 col">
                  <div class="modules-item mt-20">
                    <img src="@/assets/images/modules/services-1.png" alt="modules" style="border-radius:20px;">
                    <router-link to="/module">
                      <div class="modules-overlay" style="border-radius:20px;">
                      <h4 class="title text-center">Bernama News</h4>
                      <div class="input-box text-center">
                        <router-link to="/list" class="btn btn-danger btn-module"><i class="fa fa-search"></i></router-link>
                      </div>
                    </div>
                    </router-link>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-3 col">
                  <div class="modules-item mt-20">
                    <img src="@/assets/images/modules/services-2.png" alt="modules" style="border-radius:20px;">
                    <router-link to="/module">
                    <div class="modules-overlay" style="border-radius:20px;">
                      <h4 class="title text-center">Archival News</h4>
                      <div class="input-box text-center">
                        <router-link to="/list" class="btn btn-danger btn-module"><i class="fa fa-search"></i></router-link>
                      </div>
                    </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="rowb">
                <div class="col-lg-2 col-md-3 col-sm-3 col">
                  <div class="modules-item mt-20">
                    <img src="@/assets/images/modules/services-3.png" alt="modules" style="border-radius:20px;">
                    <router-link to="/module">
                    <div class="modules-overlay" style="border-radius:20px;">
                      <h4 class="title text-center">Mandarin News</h4>
                      <div class="input-box text-center">
                        <router-link to="/list" class="btn btn-danger btn-module"><i class="fa fa-search"></i></router-link>
                      </div>
                    </div>
                    </router-link>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-3 col">
                  <div class="modules-item mt-20">
                    <img src="@/assets/images/modules/services-4.png" alt="modules" style="border-radius:20px;">
                    <router-link to="/module">
                    <div class="modules-overlay" style="border-radius:20px;">
                      <h4 class="title text-center">Foreign News</h4>
                      <div class="input-box text-center">
                        <router-link to="/list" class="btn btn-danger btn-module"><i class="fa fa-search"></i></router-link>
                      </div>
                    </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="rowb">
              <div class="col-lg-2 col-md-3 col-sm-3 col">
                <div class="modules-item mt-20">
                  <img src="@/assets/images/modules/services-5.png" alt="modules" style="border-radius:20px;">
                  <router-link to="/module">
                  <div class="modules-overlay" style="border-radius:20px;">
                    <h4 class="title text-center">Info Providers</h4>
                    <div class="input-box text-center">
                      <router-link to="/list" class="btn btn-danger btn-module"><i class="fa fa-search"></i></router-link>
                    </div>
                  </div>
                    </router-link>
                </div>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-3 col">
                <div class="modules-item mt-20">
                  <img src="@/assets/images/modules/services-6.png" alt="modules" style="border-radius:20px;">
                  <router-link to="/module">
                  <div class="modules-overlay" style="border-radius:20px;">
                    <h4 class="title text-center">Sectoral Reports</h4>
                    <div class="input-box text-center">
                      <router-link to="/list" class="btn btn-danger btn-module"><i class="fa fa-search"></i></router-link>
                    </div>
                  </div>
                  </router-link>
                </div>
              </div>
            </div>
              <!-- <div class="col-lg-2 col-md-6 col-sm-6"> -->
              <div class="rowb">
                <div class="col-lg-2 col-md-3 col-sm-3 col">
                  <div class="modules-item mt-20">
                    <img src="@/assets/images/modules/services-7.png" alt="modules" style="border-radius:20px;">
                    <router-link to="/module">
                    <div class="modules-overlay" style="border-radius:20px;">
                      <h4 class="title text-center">Who's Who</h4>
                      <div class="input-box text-center">
                        <router-link to="/list" class="btn btn-danger btn-module"><i class="fa fa-search"></i></router-link>
                      </div>
                    </div>
                    </router-link>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-3 col">
                  <div class="modules-item mt-20">
                    <img src="@/assets/images/modules/services-8.png" alt="modules" style="border-radius:20px;">
                    <router-link to="/module">
                    <div class="modules-overlay" style="border-radius:20px;">
                      <h4 class="title text-center">Speeches</h4>
                      <div class="input-box text-center">
                        <router-link to="/list" class="btn btn-danger btn-module"><i class="fa fa-search"></i></router-link>
                      </div>
                    </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="rowb">
                <div class="col-lg-2 col-md-3 col-sm-3 col">
                  <div class="modules-item mt-20">
                    <img src="@/assets/images/modules/services-9.png" alt="modules" style="border-radius:20px;">
                    <router-link to="/module">
                    <div class="modules-overlay" style="border-radius:20px;">
                      <h4 class="title text-center">Facts and Figures</h4>
                      <div class="input-box text-center">
                        <router-link to="/list" class="btn btn-danger btn-module"><i class="fa fa-search"></i></router-link>
                      </div>
                    </div>
                    </router-link>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-3 col">
                  <div class="modules-item mt-20">
                    <img src="@/assets/images/modules/services-10.png" alt="modules" style="border-radius:20px;">
                    <router-link to="/module">
                    <div class="modules-overlay" style="border-radius:20px;">
                      <h4 class="title text-center">Databank</h4>
                      <div class="input-box text-center">
                        <router-link to="/list" class="btn btn-danger btn-module"><i class="fa fa-search"></i></router-link>
                      </div>
                    </div>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="rowb">
                <div class="col-lg-2 col-md-3 col-sm-3 col">
                  <div class="modules-item mt-20">
                    <img src="@/assets/images/modules/services-11.png" alt="modules" style="border-radius:20px;">
                    <router-link to="/module">
                    <div class="modules-overlay" style="border-radius:20px;">
                      <h4 class="title text-center">Directory</h4>
                      <div class="input-box text-center">
                        <router-link to="/list" class="btn btn-danger btn-module"><i class="fa fa-search"></i></router-link>
                      </div>
                    </div>
                    </router-link>
                  </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-3 col">
                  <div class="modules-item mt-20">
                    <img src="@/assets/images/modules/services-12.png" alt="modules" style="border-radius:20px;">
                    <router-link to="/module">
                    <div class="modules-overlay" style="border-radius:20px;">
                      <h4 class="title text-center">Calendar of Events</h4>
                      <div class="input-box text-center">
                        <router-link to="/list" class="btn btn-danger btn-module"><i class="fa fa-search"></i></router-link>
                      </div>
                    </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <fun-fact-area />
          <!-- <div class="post_gallery_inner_slider" style="position: relative">
            <span
              @click="postGalleryPrev"
              class="prev slick-arrow"
              style="display: block"
              ><i class="fal fa-angle-left"></i
            ></span>
            <slider :settings="miniCardsSettings" ref="miniCards">
              <template v-for="(multi, index) in postGallery">
                <div class="item" :key="index">
                  <img
                    :src="
                      require(`@@/assets/images/modules/gallery-post/${multi.picture}`)
                    "
                    alt=""
                  />
                </div>
              </template>
            </slider>
            <span
              @click="postGalleryNext"
              class="next slick-arrow"
              style="display: block"
              ><i class="fal fa-angle-right"></i
            ></span>
          </div> -->
        </div>
        <div class="col-lg-4">
          <home-one
            :trendingShortPost="false"
            :signup="false"
            :trendingBigPost="false"
            :ad="false"
            :sharePost="false"
            :darkClass="darkClass"
            role="sidebar"
            :datas="smallPostGallery"
          />
        </div>
      </div>
    </div>
    <!-- <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <fun-fact-area />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import postGallery from "../Data/BigVideoCardWithDes";
// import Slider from "../Helpers/Slider.vue";
import smallPostGallery from "../Data/NewsRowCard";
// import BigVideoCardWithDes from "../Utility/Cards/BigVideoCardWithDes.vue";
import HomeOne from "../Utility/Sidebar/StyleOne.vue";
import FunFactArea from './FunFactArea.vue';
export default {
  components: { HomeOne, FunFactArea },
  props: {
    darkClass: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    //post gallery
    postGallery: postGallery.data,
    smallPostGallery: smallPostGallery.data,
    selectedGallery: "popular",
    miniCardsSettings: {
      slidesToShow: 8,
      slideToScroll: 1,
      autoplay: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slideToScroll: 1,
            autoplay: true,
          },
        },
      ],
    },
    BigCardsSettings: {
      slidesToShow: 1,
      slideToScroll: 1,
      autoplay: true,
      arrows: false,
    },
  }),
  methods: {
    //post gallery
    postGalleryPrev() {
      this.$refs.BigCards.prev();
      this.$refs.miniCards.prev();
    },
    postGalleryNext() {
      this.$refs.BigCards.next();
      this.$refs.miniCards.next();
    },
    selectGalleryTab(value) {
      this.selectedGallery = value;
    },
  },
};
</script>

<style>
  .modules-area .modules-bg .modules-item .modules-overlay a{
    color:#fff !important;
    position:inherit !important;
  }
</style>
