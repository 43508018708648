import { render, staticRenderFns } from "./AboutPage.vue?vue&type=template&id=7a5b6a0c&scoped=true&"
import script from "./AboutPage.vue?vue&type=script&lang=js&"
export * from "./AboutPage.vue?vue&type=script&lang=js&"
import style0 from "./AboutPage.vue?vue&type=style&index=0&id=7a5b6a0c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a5b6a0c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VCardText,VIcon,VTab,VTabItem,VTabs})
