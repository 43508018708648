<template>
  <div>
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />
    <Header @toggleSidebar="toggleSidebar" />
    <PageHeader title="Rate Sheet" />
    <v-container>
    <p class="body-1 text-center mb-4">
      Rate Sheet
    </p>
    <div class="pricing-wrap">
      <v-row align="center">
        <v-col
          md="3"
          sm="6"
          cols="12"
          class="px-5"
        >
          
    <v-card class="center-box price-card">
    <v-card-title class="price-header justify-center">
      <h5 class="text-xs-center headline"> title</h5>
      <div class="small-title text-xs-center body-1">  Item List </div>
      <v-icon
        v-if="title === 'Pro'"
        class="icon"
      >
        mdi-star-outline
      </v-icon>
    </v-card-title>
    <v-card-text class="pa-4">
      <div class="card-pricing">
        <h2 class="price text-xs-center display-2">
          $  price 
        </h2>
        <h6 class="title">
          / mo
        </h6>
      </div>
      <ul>
        <li
          v-for="(line, index) in description"
          :key="index"
          class="text-center subtitle-1"
        >
           line 
        </li>
      </ul>
    </v-card-text>
    <div class="text-center pa-4">
      <v-btn
        :outlined="buttonVariant === 'outlined'"
        block
        color="primary"
      >
         buttonText 
      </v-btn>
    </div>
  </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
    <footer-one />
      <div class="go-top-area">
        <div class="go-top-wrap">
          <div class="go-top-btn-wrap">
            <a href="#" class="go-top go-top-btn d-block">
              <i class="fa fa-angle-double-up"></i>
              <i class="fa fa-angle-double-up"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import PageHeader from "../Utility/Header/PageHeader.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
import Header from "../Utility/Header/index";
export default {
  components: { Drawer, PageHeader, FooterOne, Header },
  data: () => ({
    sidebar: false,
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style>
  .ec-footer{
    padding-bottom:20px;
    padding-top:40px;
  }
  .ec-footer li{
    list-style: none;;
  }
  ul.ec-footer__row{
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
    padding-bottom:10px;
    border-bottom: 1px dashed gray;
  }
  .ec-footer__cell
  {
    width: 150px;
  }
  .ec-link--muted{
    color: #757575;
    transition: color .12s ease-out 0s;
  }
  .ec-cart__message{
    padding-top:40px;
    padding-bottom:40px;
    font-weight:700;
  }
</style>
