<template>
  <header class="header-area header-style-2">
      <div class="header-menubar">
        <div class="containerfull">
          <div class="menubar-bg">
            <div class="row align-items-center">
              <div class="col-lg-2 col-sm-10 col-10">
                <div class="logo">
                <router-link to="/">
                  <img src="@/assets/images/logo.png" alt="logo" />
                </router-link>
              </div>
              </div>
              <div class="col-lg-6 col-sm-2 col-2">
                <svg
                  class="menu-drawer"
                  style="cursor: pointer"
                  @click.prevent="hideSidebar"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  fill="currentColor"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    d="M3 4h18v2H3V4zm6 7h12v2H9v-2zm-6 7h18v2H3v-2z"
                  ></path>
                </svg>
                <nav-items class="header-two" />
              </div>
              <div class="col-lg-4 col-sm-2 col-2 text-right">
                <div class="header-two blisportal-header-main-menu">
                  <ul>
                    <li><router-link to="/cart"><i class="fa fa-shopping-cart"></i> (0)</router-link>
                    </li>
                    <li><a href="#login" @click="showModal">Login <i class="fal fa-sign-in"></i> </a></li>
                    <Modal v-show="isModalVisible" @close="closeModal"/>
                    <li><a><img src="@/assets/images/author.png" alt="logo" width="30px"/> Welcome User <i class="fal fa-sign-out"></i> </a>
                      <ul class="sub-menu">
                        <li><router-link to="/profile" class=""><i class="fa fa-user"></i> Profile</router-link></li>
                        <li><router-link to="/userhistory" class=""><i class="fa fa-clock"></i> History</router-link></li>
                        <li><router-link to="/subscription" class=""><i class="fa fa-rocket"></i> Subscription</router-link></li>
                        <li><router-link to="/wallet" class=""><i class="fa fa-wallet"></i> Wallet </router-link></li>
                        <li><router-link to="/logout" class=""><i class="fa fa-sign-out"></i> Logout </router-link></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- <div class="col-lg-3 col-sm-9 col-9">
                <div class="header-menu-rightbar">
                  <div class="header-date">
                    <p>{{currentDate()}}</p>
                  </div>
                </div>
              </div> -->
              <div class="c-btn float-right">
                <Bubble right>
                <span><div style="padding:5px;font-size:30px;"><i aria-hidden="true" class="fa fa-wheelchair"></i></div> FACILITIES FOR PEOPLE WITH DISABILITIES W3C 
                
                </span>
                <span>Color Blind Users</span>
                  <div class="search-up-heading">
                    <div class="d-flex text-center">
                      <router-link to="" id="maroon" href="#" class="butang btn-maroon btn-text text-arial">T</router-link>
                      <router-link to="" id="blue" href="#" class="butang btn-blue btn-text text-arial">T</router-link>
                      <router-link to="" id="yellow" href="#" class="butang btn-yellow btn-text text-arial">T</router-link>
                      <router-link to="" id="yellow" href="#" class="btn-text btn-reset">Reset</router-link>
                    </div>
                  </div>
                <span>Poor Vision Users</span>
                <div class="search-up-heading">
                    <div class="d-flex text-center">
                      <router-link to="" id="maroon" href="#" class="butang btn-indutri btn-text text-arial">A+</router-link>
                      <router-link to="" id="maroon" href="#" class="butang btn-indutri btn-text text-arial">A</router-link>
                      <router-link to="" id="maroon" href="#" class="butang btn-indutri btn-text text-arial">A-</router-link>
                    </div>
               </div>
                </Bubble>
                <router-link to="/" class="v-btn v-btn--icon v-btn--round theme--light v-size--default">
                <span class="v-btn__content">
                <i aria-hidden="true" class="fa fa-wheelchair v-icon">
                </i>
                </span>
                </router-link>
                <router-link to="/" class="v-btn v-btn--icon v-btn--round theme--light v-size--default">
                <span class="v-btn__content">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-whatsapp v-icon" viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
</svg>
                </span>
                </router-link> 
                <router-link to="/help" class="v-btn v-btn--icon v-btn--round theme--light v-size--default">
                <span class="v-btn__content">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
</svg>
                </span>
                </router-link>
            </div>
            </div>
          </div>
        </div>
      </div>
      <announcement-area />
      <global-search />
      <!-- <slider /> -->
    </header>
</template>

<script>
import NavItems from "../Common/NavItems.vue";
import AnnouncementArea from "./AnnouncementArea.vue";
import GlobalSearch from "./GlobalSearch.vue";
import { Bubble } from 'vue-burger-menu'
import Modal from '../../Modal.vue';
export default {
  components: { NavItems,AnnouncementArea,GlobalSearch,Modal,Bubble },
  props: {
    image: {
      type: String,
      default: "logo.png",
    },
    darkClass: {
      type: Object,
    },
  },
    data() {
      return {
        isModalVisible: false,
      };
    },
  methods: {
    hideSidebar(e) {
      this.$emit("toggleSidebar", e);
    },
    showModal() {
        this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    }
  },
};
</script>
<style>
.select-lang {
  border: 1px solid #bababa;
  width: 100px;
  padding: 2px;
  color: #17191d;
  outline: none;
  border-radius: 7px;
}
.blisportal-header-main-menu ul > li .sub-menu > li a i{
  float:none !important;
}
.btn-indutri{
  margin-top:0 !important;
  font-size: 22px !important;
    line-height: 40px !important;
    margin-right: 7px;
    color: #000 !important;
    font-weight: 400 !important;
    background-color: #fff !important;
  font-family: Arial, Helvetica, sans-serif !important;
}
.btn-maroon{
background-color: rgb(128, 17, 0);
    min-width: 28px;
    color: rgb(255, 255, 255) !important;
    font-size: 24px !important;
    line-height: 40px !important;
    margin-right: 7px;
    font-weight: 400 !important;
  font-family: Arial, Helvetica, sans-serif !important;
    }
.btn-reset{
background-color: #ddd;
    min-width: 28px;
    color: rgb(0, 0, 0) !important;
    /* font-size: 24px !important; */
    line-height: 40px !important;
    margin-right: 7px;
    font-weight: 400 !important;
  /* font-family: Arial, Helvetica, sans-serif !important; */
    }
.btn-blue{
  background-color: rgb(0, 0, 255);
    min-width: 28px;
    color: rgb(255, 255, 255) !important;
    font-size: 24px !important;
    line-height: 40px !important;
    margin-right: 7px;
    font-weight: 400 !important;
  font-family: Arial, Helvetica, sans-serif !important;
}
.btn-yellow{
  background-color: rgb(255, 255, 0);
    min-width: 28px;
    color: rgb(0, 0, 0) !important;
    font-size: 24px !important;
    line-height: 40px !important;
    margin-right: 7px;
    font-weight: 400 !important;
  font-family: Arial, Helvetica, sans-serif !important;
}
.btn-text {
            /* position: absolute;
            margin-right:10px; */
            /* margin-top: 26px; */
            transform: translateY(-50%);
            height: 40px !important;
            width: 40px !important;
            text-align: center;
            /* border-radius: 10px !important; */
            /* background: #fff; */
            border: 2px solid #000;
            padding: 0 0 !important;
            color: inherit;
            font-size: 14px;
            z-index: 10;
            -webkit-transition: all 0.4s ease-out 0s;
            -moz-transition: all 0.4s ease-out 0s;
            -ms-transition: all 0.4s ease-out 0s;
            -o-transition: all 0.4s ease-out 0s;
            transition: all 0.4s ease-out 0s; }
            .btn-text::before {
              position: absolute;
              content: '';
              left: 0;
              top: 0;
              height: 100%;
              width: 100%;
              background: inherit !important;
              border: 2px solid #fff;
              z-index: -1;
              transform: scaleX(0);
              -webkit-transition: all 0.4s ease-out 0s;
              -moz-transition: all 0.4s ease-out 0s;
              -ms-transition: all 0.4s ease-out 0s;
              -o-transition: all 0.4s ease-out 0s;
              transition: all 0.4s ease-out 0s; }
            .btn-text:hover {
              color: #fff; }
              .btn-text:hover::before {
                transform: scaleX(1); }
</style>
