<template>
  <div class="about-author-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="about-author-content">
            <!-- <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item" aria-current="page">
                  Module
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Bernama News
                </li>
              </ol>
            </nav> -->
            <div class="archive-content">
              <div class="row">
                <div class="col-lg-3 col-md-4">
                  <div class="archive-items">
                    <div class="section-title">
                      <h3 class="title">Folder</h3>
                    </div>
                    <div class="archive-list">
                      <select class="input-box">
                        <option disabled value="">Please select one</option>
                        <option>Berita Dalam Negeri</option>
                        <option>Berita Ekonomi</option>
                        <option>Economic News</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-9 col-md-8">
                  <div class="archive-items border-left">
                    <div class="section-title">
                      <h3 class="title">Article Date</h3>
                    </div>
                    <div class="archive-list">
                      <select class="select-date">
                        <option disabled>Select Year</option>
                        <option>2022</option>
                        <option>2021</option>
                        <option>2020</option>
                      </select>
                      <select class="select-date">
                        <option disabled value="">Select Month</option>
                        <option>January</option>
                        <option>February</option>
                        <option>March</option>
                      </select>
                      <select class="select-date">
                        <option disabled value="">Select Day</option>
                        <option>01</option>
                        <option>02</option>
                        <option>03</option>
                      </select>
                      <button class="btn-search"><i class="fa fa-search"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
select.input-box {
    width: 100%;
    height: 40px;
    padding-left: 20px;
}
select.select-date{
  height: 40px;
    padding-left: 20px;
    margin-right:10px;
}
.btn-search {
    /* position: absolute; */
    right: 30px;
    top: 50%;
    /* transform: translateY(-50%); */
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    background: #009ed8;
    border: 0;
    color: #fff;
    font-size: 14px;
    z-index: 10;
    transition: all 0.4s ease-out 0s;
    border-radius: 5px;
}
</style>
