import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Contact from '../views/Contact.vue'
import About from '../views/About.vue'
import Help from '../views/Help.vue'
import Module from '../views/Module.vue'
import List from '../views/List.vue'
import GlobalSearch from '../views/GlobalSearch.vue'
import UserHistory from '../views/UserHistory.vue'
import Cart from '../views/Cart.vue'
import Wallet from '../views/Wallet.vue'
import Subscription from '../views/Subscription.vue'
import RateSheet from '../views/RateSheet.vue'
import Profile from '../views/Profile.vue'
import fourOfour from '../components/404.vue'
import PostOne from '../components/Posts/PostOne/index.vue'
import PostTwo from '../components/Posts/PostTwo/index.vue'
import PostThree from '../components/Posts/PostThree/index'
import Business from '../components/category/Buisness.vue'
import Entertainment from '../components/category/Entertainment.vue'
import Features from '../components/category/Feature.vue'
import Sports from '../components/category/Sports.vue'
import Trending from '../components/category/Trending.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/about-us',
    name: 'About',
    component: About
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/module',
    name: 'Module',
    component: Module
  },
  {
    path: '/list',
    name: 'List',
    component: List
  },
  {
    path: '/globalsearch',
    name: 'GlobalSearch',
    component: GlobalSearch
  },
  {
    path: '/userhistory',
    name: 'UserHistory',
    component: UserHistory
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: Wallet
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: Subscription
  },
  {
    path: '/rate-sheet',
    name: 'RateSheet',
    component: RateSheet
  },
  {
    path: '/404',
    name: '404',
    component: fourOfour
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/posts/postOne',
    name: 'PostOne',
    component: PostOne
  },
  {
    path: '/posts/posttwo',
    name: 'PostTwo',
    component: PostTwo
  },
  {
    path: '/posts/postthree',
    name: 'PostThree',
    component: PostThree
  },
  {
    path: '/business',
    name: 'Business',
    component: Business
  },
  {
    path: '/entertainment',
    name: 'Entertainment',
    component: Entertainment
  },
  {
    path: '/features',
    name: 'Features',
    component: Features
  },
  {
    path: '/sports',
    name: 'Sports',
    component: Sports
  },
  {
    path: '/trending',
    name: 'Trending',
    component: Trending
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
