<template>
  <GlobalSearch />
</template>

<script>
import GlobalSearch from "../components/GlobalSearch/index";
export default {
  components: { GlobalSearch },
};
</script>

<style></style>
