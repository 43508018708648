<template>
<div>
  <div class="d-flex justify-content-center">
    <div class=""><button v-on:click="toggle" class="btn btn-switch ml-3 mt-3 mb-5"><i class="fa fa-sync"></i>&nbsp; Switch View</button></div>
  </div>
  <keep-alive>
  <component v-bind:is="component" />
</keep-alive>
    
  <!-- <grid-view />
  <list-view /> -->
  </div>
</template>

<script>
import GridView from "./GridView.vue";
import ListView from "./ListView.vue";
export default {
  name: 'app',
  components: {
    GridView,
     ListView
  },
  data (){
    return {
      component:"ListView"
    }
  },
  methods: {
    toggle(){
      if (this.component === GridView) {
        this.component = ListView;
      } else {
        this.component = GridView;
      }
    }
  }
}
</script>
<style>
 button.btn.btn-switch {
    color: #fff;
    background-color: #ff8723;
    border-color: #ff8723;
}
</style>