<template>
  <UserHistory />
</template>

<script>
import UserHistory from "../components/UserHistory/index";
export default {
  components: { UserHistory },
};
</script>

<style></style>
