<template>
  <rate-sheet />
</template>

<script>
import RateSheet from "../components/RateSheet/index";
export default {
  components: { RateSheet },
};
</script>

<style></style>
