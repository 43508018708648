<template>
  <section class="about-item-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="about-tab-btn">
            <div class="archive-btn">
                  <ul>
                      <li>Bernama News</li>
                      <!-- <li><div style="font-size:17px;" id="list_article_info" role="status" aria-live="polite">Showing 1 to 16 of 16 entries </div></li> -->
                      <li>
                        <div class="search-wrapper panel-heading col-sm-12">
                            <input class="form-control" type="text" placeholder="Search" />
                        </div> 
                      </li>
                      <!-- <li><button class="button btn-search" v-on:click="toggle"><i class="fal fa-th"></i></button></li> -->
                  </ul>
              </div>
              <div class="about-post-items">
                  <div class="row">
                  <div class="col-sm-12">
                    <div id="list_article_info" role="status" aria-live="polite" style="font-size:17px;">Showing 1 to 16 of 16 entries </div>
                  </div>
                </div>
                  <div class="row">
                    <table class="w-100 table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Date</th>
                          <th>
                            Article
                          </th>
                        </tr>
                      </thead>
                      <tbody v-for="(data,index) in aboutDatas" :key="index">
                        <tr>
                          <td><img :src="require(`@/assets/images/${data.image}`)" alt="trending" width="100px"></td>
                          <td nowrap><span>{{data.category.create_At }}</span></td>
                          <td>
                            <a href="#">{{data.title}}</a>
                            <p><small>{{data.article}}</small></p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    
                      
                        <!-- <div class="col-sm-12">
                          <div class="dataTables_info ml-1" id="list_article_info" role="status" aria-live="polite">Showing 1 to 16 of 16 entries </div>
                        </div> -->
                      <div class="col-lg-12">
                          <div class="pagination-item">
                              <nav aria-label="Page navigation example">
                                  <ul class="pagination">
                                      <li class="page-item active"><a class="page-link" href="#">01</a></li>
                                      <li class="page-item"><a class="page-link" href="#">02</a></li>
                                      <li class="page-item"><a class="page-link" href="#">...</a></li>
                                      <li class="page-item"><a class="page-link" href="#">50</a></li>
                                      <li class="page-item">
                                          <a class="page-link" href="#" aria-label="Next">
                                              <span aria-hidden="true"><i class="fas fa-caret-right"></i></span>
                                          </a>
                                      </li>
                                  </ul>
                              </nav>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import styleOne from "../Utility/Sidebar/StyleOne.vue";
import aboutDatas from "../Data/About";

export default {
//   components: { styleOne},
  data: () => ({
    selected: "latest",
    aboutDatas: aboutDatas.data,
    
  })
};
</script>

<style></style>
