<template>
  <!-- <router-link to="/posts/postOne" class="d-block"> -->
    <div class="ads_gallery_play">
      <div class="bg-image">
        <img
          :src="require(`@/assets/images/ads/${datas.thumbnail}`)"
          alt=""
          style="width: 100%;"
        />
      </div>
    </div>
  <!-- </router-link> -->
</template>

<script>
export default {
  props: ["datas"],
};
</script>

<style></style>
