<template>
  <section class="contact-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="contact-sidebar">
            <!-- <FromCount /> -->
            <!-- <signup-style-one /> -->
            <div class="contact-info-item mt-30">
              <h3 class="title">BLIS Sales</h3>
              <span><i class="fas fa-envelope"></i> EMAIL:</span>
              <ul>
                <li>blissales@bernama.com</li>
              </ul>
            </div>
            <div class="contact-info-item mt-30">
              <h3 class="title">BLIS Administrator</h3>
              <span><i class="fas fa-envelope"></i> EMAIL:</span>
              <ul>
                <li>blisadmin@bernama.com</li>
              </ul>
            </div>
            <div class="contact-info-item mt-30">
            <h3 class="title">BERNAMA</h3>
            <span><i class="fas fa-map-marker-alt"></i> LOCATION:</span>
            <ul>
              <li>Malaysian National News Agency</li>
              <li>Wisma BERNAMA,</li>
              <li>No 28, Jalan 1/65A,</li>
              <li>Off Jalan Tun Razak,50400 Kuala Lumpur, Malaysia</li>
            </ul>
          </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="contact-box mt-30">
            <form action="#">
              <h3 class="title">
                CALL NOW OR FILL OUT THE FORM.
              </h3>
              <div class="row">
                <div class="col-md-6">
                  <div class="input-box">
                    <input type="text" placeholder="Full name" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-box">
                    <input type="text" placeholder="Subject" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-box">
                    <input type="email" placeholder="Email address" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-box">
                    <input type="email" placeholder="Phone number" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-box">
                    <textarea
                      name="#"
                      id="#"
                      cols="30"
                      rows="10"
                      placeholder="Tell us about your message…"
                    ></textarea>
                    <button class="btn btn-info">SEND MESSAGE</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import FromCount from "../Utility/FollowCount/index";
// import SignupStyleOne from "../Utility/Signup/SignupStyleOne.vue";
export default {
  components: { 
    // FromCount, 
  // SignupStyleOne 
  },
};
</script>

<style></style>
