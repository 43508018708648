<template>
  <div>
    <footer class="footer-area">
      <div class="footer-main">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div class="footer-widget mt-30">
                <div class="footer-title pb-30">
                  <h4 class="title">Contact</h4>
                </div>
                <div class="footer-about-content">
                  <p>Malaysian National News Agency Wisma BERNAMA<br>
                      No.28 Jalan BERNAMA<br>
                      Off Jalan Tun Razak<br>
                      50400 Kuala Lumpur,
                      Malaysia
                  <div class="email">
                    <a href="mailto:blissales@bernama.com">blissales@bernama.com</a>
                  </div>
                  <div class="call">
                    <a href="tel:60326939933">+603-2693 9933 (General Line)</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="footer-widget mt-30">
                <div class="footer-title">
                  <h4 class="title">NEWS CHANNEL</h4>
                </div>
                <div class="footer-about-list d-flex">
                  <ul>
                    <li class="mr-30"><a href="#"><i class="fa fa-angle-right"></i> BERNAMA</a></li>
                    <li class="mr-30"><a href="#"><i class="fa fa-angle-right"></i> Radio</a></li>
                    <li class="mr-30"><a href="#"><i class="fa fa-angle-right"></i> BERNAMA TV</a></li>
                  </ul>
                  <!-- <a href="#" class="text-white"><i class="fa fa-angle-right"></i> BERNAMA</a> &nbsp;|&nbsp;
                  <a href="#" class="text-white"><i class="fa fa-angle-right"></i> Radio</a>&nbsp; | &nbsp;
                  <a href="#" class="text-white"><i class="fa fa-angle-right"></i> BERNAMA TV</a> -->
                </div>
                <div class="footer-title mt-30">
                  <h4 class="title">PORTAL SERVICES</h4>
                </div>
                <div class="footer-about-list d-flex">
                  <ul>
                    <li class="mr-30"><a href="#"><i class="fa fa-angle-right"></i> Bernama.com</a></li>
                    <li class="mr-30"><a href="#"><i class="fa fa-angle-right"></i> Special Coverage</a></li>
                    <li class="mr-30"><a href="#"><i class="fa fa-angle-right"></i> Newsbriefs</a></li>
                  
                    <li class="mr-30"><a href="#"><i class="fa fa-angle-right"></i> eNewsletter</a></li>
                    <li class="mr-30"><a href="#"><i class="fa fa-angle-right"></i> eBiz</a></li>
                    <li class="mr-30"><a href="#"><i class="fa fa-angle-right"></i> SMSAlert</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="footer-widget mt-30">
                <div class="footer-title">
                  <h4 class="title">IMAGES</h4>
                </div>
                <div class="footer-about-list d-flex">
                  <ul>
                    <li class="mr-30"><a href="#"><i class="fa fa-angle-right"></i> Imagebank</a></li>
                    <li class="mr-30"><a href="#"><i class="fa fa-angle-right"></i> Publication</a></li>
                  </ul>
                </div>
                <div class="footer-title mt-30">
                  <h4 class="title">MEDIA RELATIONS & EVENTS MANAGEMENT</h4>
                </div>
                <div class="footer-about-list d-flex">
                  <ul>
                    <li class="mr-30"><a href="#"><i class="fa fa-angle-right"></i> Domestic Service</a></li>
                    <li class="mr-30"><a href="#"><i class="fa fa-angle-right"></i> International-Asianet Service</a></li><br>
                    <li class="mr-30"><a href="#"><i class="fa fa-angle-right"></i> Media Relations Service</a></li>
                    <li class="mr-30"><a href="#"><i class="fa fa-angle-right"></i> Event Management Service</a></li>
                  </ul>
                </div>
                
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="footer-widget mt-30 ml-30">
                <div class="footer-title">
                  <h4 class="title">Importants Links</h4>
                </div>
                <div class="footer-about-list d-flex">
                  <ul class="mr-70">
                    <li><a href="#"><i class="fa fa-angle-right"></i> BERNAMA</a><p>Malaysian National News Agency - Breaking News round the clock</p></li>
                    <li><a href="#"><i class="fa fa-angle-right"></i> INFOLIBSNEWS PORTAL</a><p>BERNAMA News Library and Information Center</p></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="footer-copyright">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="copyright-item d-block d-md-flex align-items-center">
                <p>Copyright © 2022 BERNAMA , All Rights Reserved  <small> (  Recommended browser: Google Chrome (1280*768) )</small></p>
                <ul class="page-footer-link">
                  <li><a href="#">Disclaimer</a></li>
                  <li><a href="#">Term & Conditions</a></li>
                  <li><a href="#">Site Map</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-shape-2  animated wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
        <img src="@/assets/images/shape/shape-6.png" alt="">
      </div>
      <div class="footer-shape-1">
        <img src="@/assets/images/shape/shape-5.png" alt="">
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
