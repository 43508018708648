<template>
<div class="mt-10">
  <v-card>
    <v-card-title>
      User's Searching History
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="histories"
      :search="search"
    ></v-data-table>
  </v-card>
  <!-- <div class="d-flex justify-content-center">
    <div class=""><button v-on:click="toggle" class="btn btn-switch ml-3 mt-3 mb-5"><i class="fa fa-sync"></i>&nbsp; Switch View</button></div>
  </div>
  <keep-alive>
  <component v-bind:is="component" /> -->
  <!-- <list-view />> -->
<!-- </keep-alive> -->
    
  <!-- <grid-view />
  <list-view /> -->
  </div>
</template>

<script>
// import GridView from "./GridView.vue";
// import ListView from "./ListView.vue";
export default {
    data () {
      return {
        search: '',
        headers: [
          {
            text: 'Date',
            align: 'start',
            filterable: false,
            value: 'date',
          },
          { text: 'Page', value: 'page' },
          { text: 'History', value: 'history' },
        ],
        histories: [
          {
            date: '21/02/2022 11:00 am',
            page: 'Global Search',
            history: 'Malaysia',
          },
          {
            date: '21/02/2022 11:01 am',
            page: 'Global Search',
            history: 'Malaysia Baru',
          },
          {
            date: '21/02/2022 11:03 am',
            page: 'Bernama News',
            history: 'Pembentukan Malaysia',
          },
          
        ],
      }
    },
  }
</script>
<style>
 button.btn.btn-switch {
    color: #fff;
    background-color: #ff8723;
    border-color: #ff8723;
}
</style>