<template>
  <section class="single-service-area">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-3 col-sm-3 order-lg-1">
          <div class="service-sidebar mt-30">
            <div class="service-sidebar-list">
              <ul>
                <li><a href="#">Account</a></li>
                <li><a href="#">Transactions History</a></li>
                <li><a href="#">Purchase History</a></li>
                <li><a href="#">Reload History</a></li>
              </ul>
            </div>
            <!-- <div class="service-sidebar-help">
              <h4 class="title">Need Help?</h4>
              <p>Call us and we will connect you with a team member who can help.</p>
              <span><i class="fa fa-phone"></i> 603-2693 9933</span>
            </div> -->
          </div>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 order-lg-2">
          <div class="single-project">
            <!-- <div class="single-project-thumb">
              <img src="/assets/images/single-project-thumb-1.jpg" alt="">
            </div> -->
            <div class="single-project-content">
              <h3 class="title">Account</h3>
              <p>Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the ndustry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries. Lorem Ipsum is simply dummy text of the new design printng and type setting Ipsum Take a look at our round up of the best shows coming soon to your telly box has been the is industrys. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has industr standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of.</p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
    export default {
        name: "Wallet"
    }
</script>

<style scoped>

</style>
