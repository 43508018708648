<template>
  <subscription />
</template>

<script>
import Subscription from "../components/Subscription/index";
export default {
  components: { Subscription },
};
</script>

<style></style>
