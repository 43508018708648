<template>
  <!-- <section class="single-service-area">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-3 col-sm-3 order-lg-1">
          <div class="service-sidebar mt-30">
            <div class="service-sidebar-list">
              <ul>
                <li><a href="#">Lorem ipsum</a></li>
                <li><a href="#">Lorem ipsum</a></li>
                <li><a href="#">Lorem ipsum</a></li>
                <li><a href="#">Lorem ipsum</a></li>
                <li><a href="#">Lorem ipsum</a></li>
                <li><a href="#">Lorem ipsum</a></li>
              </ul>
            </div>
            <div class="service-sidebar-help">
              <h4 class="title">Need Help?</h4>
              <p>Call us and we will connect you with a team member who can help.</p>
              <span><i class="fa fa-phone"></i> 603-2693 9933</span>
            </div>
          </div>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 order-lg-2">
          <div class="single-project mt-30">
            <div class="single-project-thumb">
              <img src="/assets/images/single-project-thumb-1.jpg" alt="">
            </div>
            <div class="single-project-content">
              <h3 class="title">HELP</h3>
              <p>Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the ndustry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries. Lorem Ipsum is simply dummy text of the new design printng and type setting Ipsum Take a look at our round up of the best shows coming soon to your telly box has been the is industrys. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has industr standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of.</p>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="single-project-thumb mt-30">
                  <img src="/assets/images/single-project-thumb-2.jpg" alt="">
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="single-project-thumb mt-30">
                  <img src="/assets/images/single-project-thumb-3.jpg" alt="">
                </div>
              </div>
            </div>
            <div class="single-project-text">
              <p>Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the ndustry stan when an unknown printer took a galley.</p>
              <p class="text">When an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like  including versions of.</p>
              <a class="main-btn mt-55" href="#">Refer to BLIS User Manual.</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <v-card>
    <v-tabs vertical class="ml-5" align-with-title>
      <v-tab class="mt-5">
        <v-icon left>
          mdi-book-check
        </v-icon>
        SUBSCRIPTION
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-note-text-outline
        </v-icon>
        ARTICLES
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-apps
        </v-icon>
        OTHERS
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-book-open-page-variant
        </v-icon>
        BLIS Manual
      </v-tab>
      <div class="service-sidebar-help mb-5">
        <h4 class="title">Need Help?</h4>
        <p>Call us and we will connect you with a <br>team member who can help.</p>
        <span><i class="fa fa-phone"></i> 603-2693 9933</span>
      </div>
      <v-tab-item class="">
        <v-card flat>
          <v-card-text>
              <h3 class="title">Subscription</h3>
              <v-expansion-panels focusable >
                <v-expansion-panel
                  v-for="(item,i) in 5"
                  :key="i"
                >
                  <v-expansion-panel-header>
                    Item
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
              <h3 class="title">Articles</h3>
              <v-expansion-panels focusable >
                <v-expansion-panel
                  v-for="(item,i) in 5"
                  :key="i"
                >
                  <v-expansion-panel-header>
                    Item
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
              <h3 class="title">Others</h3>
              <v-expansion-panels focusable >
                <v-expansion-panel
                  v-for="(item,i) in 5"
                  :key="i"
                >
                  <v-expansion-panel-header>
                    Item
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
              <h3 class="title">BLIS Manual</h3>
              <v-expansion-panels focusable >
                <v-expansion-panel
                  v-for="(item,i) in 5"
                  :key="i"
                >
                  <v-expansion-panel-header>
                    Item
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    You may refer to BLIS User Manual for more details. Or you can contact our Sales person or Administrator (<a href="/contact">here</a>) for more help.
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
    export default {
        name: "BlisHelp"
    }
</script>

<style scoped>
.v-tabs{
  width:100%;
}
p{
  color: #7f8692;
    line-height: 34px;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: .6px;
    padding-bottom: 25px;
}
</style>
