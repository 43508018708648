<template>
  <div class="about-author-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex justify-content-center bd-highlight mb-3">
            <div class="p-2">
              <div class="archive-items">
                <div class="section-title">
                  <h6>Modules</h6>
                </div>
                <div class="archive-list">
                  <select class="input-box">
                    <option disabled value="">Please select</option>
                    <option>All Module</option>
                    <option>Bernama News</option>
                    <option>Archival News</option>
                    <option>Mandarin News</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="p-2">
              <div class="archive-items">
                <div class="section-title">
                  <h6>Title</h6>
                </div>
                <div class="archive-list">
                  <input class="input-box" type="text" placeholder="Title" />
                </div>
              </div>
            </div>
            <div class="p-2">
              <div class="archive-items">
                <div class="section-title">
                  <h6>Start Date</h6>
                </div>
                <datepicker class="datepicker"></datepicker>
              </div>
            </div>
            <div class="p-2">
              <div class="archive-items">
                <div class="section-title">
                  <h6>End Date</h6>
                </div>
                <datepicker></datepicker>
              </div>
            </div>
            <div class="p-2">
              <div class="archive-items">
                <div class="section-title">
                  &nbsp;
                </div>
                <button class="btn-search"><i class="fa fa-filter"></i></button>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
export default {
  components: {
    Datepicker
  },
};
</script>

<style>
select.input-box {
    width: 100%;
    height: 40px;
    padding-left: 20px;
}
select.select-date{
  height: 40px;
    padding-left: 20px;
    margin-right:10px;
}
.btn-search {
    /* position: absolute; */
    right: 30px;
    top: 50%;
    /* transform: translateY(-50%); */
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    background: #009ed8;
    border: 0;
    color: #fff;
    font-size: 14px;
    z-index: 10;
    transition: all 0.4s ease-out 0s;
    border-radius: 5px;
}
</style>
