<template>
  <cart />
</template>

<script>
import cart from "../components/Cart/index";
export default {
  components: { cart },
};
</script>

<style></style>
