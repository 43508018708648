<template>
  <section class="about-item-area">
    <div class="container">
      <div class="row">
        <!-- <div class="col-lg-3 col-md-3 col-sm-3 order-lg-1">
          <div class="service-sidebar mt-30">
            <div class="service-sidebar-list">
              <ul>
                <li><a href="#">Lorem ipsum</a></li>
                <li><a href="#">Lorem ipsum</a></li>
                <li><a href="#">Lorem ipsum</a></li>
                <li><a href="#">Lorem ipsum</a></li>
                <li><a href="#">Lorem ipsum</a></li>
                <li><a href="#">Lorem ipsum</a></li>
              </ul>
            </div>
            <div class="service-sidebar-help">
              <h4 class="title">Need Help?</h4>
              <p>Call us and we will connect you with a team member who can help.</p>
              <span><i class="fa fa-phone"></i> 603-2693 9933</span>
            </div>
          </div>
        </div>   -->
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="about-tab-btn">
              <div class="archive-btn">
                  <ul>
                      <li>Info Providers</li>
                      <li><div style="font-size:15px;" id="list_article_info" role="status" aria-live="polite">Showing 1 to 16 of 16 entries </div></li>
                      <li>
                        <div class="search-wrapper panel-heading col-sm-12">
                            <input class="form-control" type="text" placeholder="Search" />
                        </div> 
                      </li>
                      <!-- <li><button class="button btn-search" v-on:click="toggle"><i class="fal fa-list-alt"></i></button></li> -->
                  </ul>
              </div>
              <div class="about-post-items">
                <div class="about-tab-btn mt-40">
                <div class="about-post-items">
                  <div class="row">
                    <div class="col-md-3" v-for="(data,index) in aboutDatas" :key="index">
                      <div class="feature-news-item">
                        <div class="feature-news-thumb">
                          <img :src="require(`@/assets/images/${data.image}`)" alt="trending">
                          <div class="meta-categores">
                            <span>{{data.category.name }}</span>
                          </div>
                        </div>
                        <div class="feature-news-content">
                          <div class="post-meta"><div class="feature-news-date"><span>{{data.category.create_At }}</span></div></div>
                          <p>
                            {{data.title}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <!-- <div class="row">
                    <div v-for="(data,index) in aboutDatas" :key="index" class="col-lg-3 col-md-3">
                        <div class="trending-news-item mb-30">
                            <div class="trending-news-thumb">
                                <img :src="require(`@/assets/images/${data.image}`)" alt="trending">
                            </div>
                            <div class="trending-news-content">
                                <div class="post-meta">
                                    <div class="meta-categories">
                                        <a href="#">{{data.category.name }}</a>
                                    </div>
                                    <div class="meta-date">
                                        <span>{{data.category.create_At }}</span>
                                    </div>
                                </div>
                                <h3 class="title"><a href="#">{{data.title}}</a></h3>
                                <p class="text">{{data.article}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="pagination-item">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    <li class="page-item active"><a class="page-link" href="#">01</a></li>
                                    <li class="page-item"><a class="page-link" href="#">02</a></li>
                                    <li class="page-item"><a class="page-link" href="#">...</a></li>
                                    <li class="page-item"><a class="page-link" href="#">50</a></li>
                                    <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true"><i class="fas fa-caret-right"></i></span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div> -->
              </div>
          </div>
        </div>
        <!-- <div class="col-lg-3">
          <styleOne class="mt-30" role="sidebar" :datas="aboutDatas"  :signup="true" :trendingShortPost="true" :trendingBigPost="true" :ad="true" />
          
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
// import styleOne from "../Utility/Sidebar/StyleOne.vue";
import aboutDatas from "../Data/About";

export default {
//   components: { styleOne},
  data: () => ({
    selected: "latest",
    aboutDatas: aboutDatas.data,
    
  })
};
</script>

<style>
  .feature-news-date{
    font-size:15px;
    color:gray;
  }
</style>
