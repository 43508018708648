<script>
  export default {
    name: 'Modal',
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
  
          <!-- Modal body -->
          <div class="modal-body seminor-login-modal-body">
           <h5 class="modal-title text-center">LOGIN TO MY ACCOUNT</h5>
            <button type="button" class="close" data-dismiss="modal"  @click="close">
                <span><i class="fa fa-times-circle" aria-hidden="true"></i></span>
            </button>  
            <form class="seminor-login-form">
            <div class="form-group">
              <input type="email" class="form-control" required autocomplete="off">
              <label class="form-control-placeholder" for="name">Email address</label>
            </div>
            <div class="form-group">
              <input type="password" class="form-control" required autocomplete="off">
              <label class="form-control-placeholder" for="password">Password</label>
            </div>
            <div class="form-group">
              <label class="container-checkbox">
              Remember Me On This Computer
              <input type="checkbox" checked="checked" required>
              <span class="checkmark-box"></span>
              </label>
              </div>
      
              <div class="btn-check-log">
                  <button type="submit" class="btn-check-login">LOGIN</button>
              </div>
            <div class="forgot-pass-fau text-center pt-3">
              <a href="#" class="text-secondary">Forgot Your Password?</a>
            </div>
            <!-- <div class="create-new-fau text-center pt-3">
                <a href="#" class="text-primary-fau"><span data-toggle="modal" data-target="#sem-reg" data-dismiss="modal">Create A New Account</span></a>
            </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style>
  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
</style>