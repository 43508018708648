<template>
  <div>
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />
    <Header @toggleSidebar="toggleSidebar" />
    <PageHeader title="Shopping cart" />
    <div class="ec-footer text-center">
      <ul class="ec-footer__row" data-items="4">
        <li class="ec-footer__cell"><a class="ec-footer__link ec-link ec-link--muted link--icon-top footer__link--track-order" href="/cart"><span class="svg-icon"><svg width="16" height="18" viewBox="0 0 16 18" xmlns="http://www.w3.org/2000/svg"><g fill-rule="nonzero" fill="currentColor"><path d="M1 5.414V17h14V1H5.414L1 5.414zM15 0c.552 0 1 .445 1 .993v16.014c0 .548-.445.993-1 .993H1c-.552 0-1-.445-1-.993V5l5-5h10z"></path><path d="M5.108 9.832c-.19-.2-.508-.205-.707-.013-.198.19-.204.507-.012.706l2.823 2.93c.216.225.58.2.765-.05l5.153-7.04c.164-.223.115-.536-.108-.7-.222-.162-.535-.114-.698.11l-4.803 6.56L5.107 9.83zM0 5v1h6V0H5v5"></path></g></svg></span> Order History</a></li>
        <li class="ec-footer__cell"><a class="ec-footer__link ec-link ec-link--muted link--icon-top footer__link--favorites footer__link--empty" href="/cart"><span class="svg-icon"><svg width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg"><path d="M9 16c-.16 0-.32-.063-.442-.188L2.29 9.543C2.21 9.47 0 7.375 0 4.875 0 1.823 1.798 0 4.8 0 6.56 0 8.207 1.438 9 2.25 9.794 1.437 11.44 0 13.2 0 16.2 0 18 1.823 18 4.875c0 2.5-2.21 4.594-2.3 4.688l-6.258 6.25c-.12.124-.28.187-.442.187zm5.98-7.132c.105-.103.215-.216.356-.375.235-.264.467-.56.684-.88.618-.914.98-1.85.98-2.738C17 2.373 15.647 1 13.2 1c-1.098 0-2.358.795-3.485 1.95L9 3.68l-.715-.73C7.158 1.794 5.898 1 4.8 1 2.354 1 1 2.373 1 4.875 1 6.4 2.043 7.967 2.997 8.835L9 14.84l5.98-5.972z" fill="currentColor" fill-rule="nonzero"></path></svg></span> Subscription</a></li>
        <li class="ec-footer__cell"><a class="ec-footer__link ec-link ec-link--muted link--icon-top footer__link--shopping-cart footer__link--empty" href="/cart"><span class="svg-icon"><svg width="16" height="20" viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M1 5v13.006c0 .548.446.994.994.994h12.012c.548 0 .994-.446.994-.994V5H1zm15-1v14.006c0 1.1-.895 1.994-1.994 1.994H1.994C.894 20 0 19.105 0 18.006V4h16z" fill="currentColor" fill-rule="nonzero"></path><path d="M5 7s-.005.5-.504.5C3.996 7.5 4 7 4 7V4c0-2.21 1.79-4 4-4s4 1.79 4 4v3s-.002.5-.5.5S11 7 11 7V4c0-1.657-1.343-3-3-3S5 2.343 5 4v3z" fill="currentColor"></path></g></svg></span> Shopping Cart</a></li>
        <li class="ec-footer__cell"><a class="ec-footer__link ec-link ec-link--muted link--icon-top footer__link--sigin-in" href="#login"><span class="svg-icon signInSvgIconView"><svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"><g fill-rule="nonzero" fill="currentColor"><path d="M11 21c5.523 0 10-4.477 10-10S16.523 1 11 1 1 5.477 1 11s4.477 10 10 10zm0 1C4.925 22 0 17.075 0 11S4.925 0 11 0s11 4.925 11 11-4.925 11-11 11z"></path><path d="M18.08 18.854c-.502-.617-1.156-1.143-1.974-1.612-.168-.096-.46-.248-.902-.47-.358-.18-.97-.483-.94-.466-.543-.295-.927-.56-1.117-.782l-.155-.824c1.625-.848 2.563-2.847 2.563-5.24 0-2.96-1.872-5.04-4.555-5.04S6.446 6.5 6.446 9.46c0 2.42.945 4.427 2.588 5.26l-.135.725c-.16.214-.7.595-1.188.865.032-.018-.567.28-.973.485-.4.2-.69.353-.856.45-.81.463-1.457.986-1.956 1.595.092.29.512.498.81.588.426-.507.973-.932 1.644-1.317.407-.233 1.794-.914 1.814-.925.352-.195 1.17-.694 1.504-1.143l.14-.186.315-1.69-.67-.34c-1.25-.633-2.04-2.272-2.04-4.365 0-2.472 1.49-4.04 3.555-4.04 2.067 0 3.555 1.568 3.555 4.04 0 2.07-.784 3.705-2.026 4.353l-.657.343.34 1.816.175.204c.33.385.884.73 1.4 1.01.024.01 1.413.69 1.82.924.697.398 1.26.838 1.69 1.368.468-.186.663-.403.782-.624z"></path></g></svg></span> Sign In</a></li>
      </ul>
      <div class="ec-cart ec-cart--empty"><div class="ec-cart__message">Your shopping cart is empty</div></div>
      </div>
    <footer-one />
      <div class="go-top-area">
        <div class="go-top-wrap">
          <div class="go-top-btn-wrap">
            <a href="#" class="go-top go-top-btn d-block">
              <i class="fa fa-angle-double-up"></i>
              <i class="fa fa-angle-double-up"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Drawer from "../Mobile/Drawer.vue";
import PageHeader from "../Utility/Header/PageHeader.vue";
import FooterOne from "../Utility/Footer/FooterOne.vue";
import Header from "../Utility/Header/index";
export default {
  components: { Drawer, PageHeader, FooterOne, Header },
  data: () => ({
    sidebar: false,
  }),
  created() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    topToBottom() {
      const result = document.querySelector(".go-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("active");
      } else {
        result.classList.remove("active");
      }
    },
  },
};
</script>

<style>
  .ec-footer{
    padding-bottom:20px;
    padding-top:40px;
  }
  .ec-footer li{
    list-style: none;;
  }
  ul.ec-footer__row{
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
    padding-bottom:10px;
    border-bottom: 1px dashed gray;
  }
  .ec-footer__cell
  {
    width: 150px;
  }
  .ec-link--muted{
    color: #757575;
    transition: color .12s ease-out 0s;
  }
  .ec-cart__message{
    padding-top:40px;
    padding-bottom:40px;
    font-weight:700;
  }
</style>
